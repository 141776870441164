import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CategoryModel from "../../../interfaces/models/category.model";
import { getCategories } from "../../../services/posts.service";
import toast from "react-hot-toast";
import { Loading } from "../../../components/Loading";

export const Filtro = () => {
  const { categoryId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  async function fetchFilter(): Promise<void> {
    setLoading(true);

    getCategories()
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchFilter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="filtro mb-4">
      {/* mobile */}
      {/*<div
        className="accordion d-md-none"
        id="accordionExample"
        style={{ marginTop: "-2rem" }}
      >
        <div className="accordion-item w-50 mx-auto">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed text-gilroy-semibold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Filtrar ofertas
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="row font-weight-semibold font-size-13 mt-2">
              <Link
                className="col-12 menu-category-item mb-2 d-flex align-items-center justify-content-center"
                to={`/posts/category/00000000-0000-0000-0000-000000000000`}
              >
                <img
                  src={
                    window.location.pathname === "/" ||
                    window.location.pathname ===
                      "/posts/category/00000000-0000-0000-0000-000000000000"
                      ? "/images/categories/todas_categorias_selected.png"
                      : "/images/categories/todas_categorias.png"
                  }
                  alt="Todas"
                />
              </Link>

              {loading && <Loading center />}

              {!loading &&
                categories.map((c) => (
                  <Link
                    key={c.id}
                    className="col-12 menu-category-item mb-2 d-flex align-items-center justify-content-center"
                    to={`/posts/category/${c.id}`}
                  >
                    <img
                      src={
                        window.location.pathname.includes("/category/" + c.id)
                          ? `/images/categories/${c.systemKey}_selected.png`
                          : `/images/categories/${c.systemKey}.png`
                      }
                      alt={c.title}
                    />
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>/*}

      {/* web */}
      <div className="filtro-column d-md-block d-none">
        <div className="header-filtro">
          <h4 className="text-center fw-semibold color-primary mb-3 fs-5 text-gilroy-semibold">
            Filtrar ofertas por
          </h4>
        </div>
        <div className="row font-weight-semibold font-size-13">
          <Link
            className="col-12 menu-category-item mb-2 d-flex align-items-center justify-content-center"
            to={`/posts/category/00000000-0000-0000-0000-000000000000`}
          >
            <img
              src={
                window.location.pathname === "/" ||
                window.location.pathname ===
                  "/posts/category/00000000-0000-0000-0000-000000000000"
                  ? "/images/categories/todascategorias_selected.svg"
                  : "/images/categories/todascategorias.svg"
              }
              alt="Todas"
            />
          </Link>

          {loading && <Loading center />}

          {!loading &&
            categories.map((c) => (
              <Link
                key={c.id}
                className="col-12 menu-category-item mb-2 d-flex align-items-center justify-content-center"
                to={`/posts/category/${c.id}`}
              >
                <img
                  src={
                    window.location.pathname.includes("/category/" + c.id)
                      ? `/images/categories/${c.systemKey}_selected.svg`
                      : `/images/categories/${c.systemKey}.svg`
                  }
                  alt={c.title}
                />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};
