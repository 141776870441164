export const CloseIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1602 7.09994C24.8922 6.83137 24.5283 6.68044 24.1489 6.68044C23.7695 6.68044 23.4056 6.83137 23.1376 7.09994L16.1231 14.1001L9.10851 7.0856C8.74721 6.7243 8.2206 6.58319 7.72706 6.71544C7.23351 6.84768 6.848 7.23319 6.71576 7.72674C6.58351 8.22028 6.72462 8.74689 7.08592 9.10819L14.1005 16.1227L7.08592 23.1373C6.72462 23.4986 6.58351 24.0252 6.71576 24.5187C6.848 25.0123 7.23351 25.3978 7.72706 25.53C8.2206 25.6623 8.74721 25.5212 9.10851 25.1599L16.1231 18.1453L23.1376 25.1599C23.4989 25.5212 24.0255 25.6623 24.519 25.53C25.0126 25.3978 25.3981 25.0123 25.5303 24.5187C25.6626 24.0252 25.5215 23.4986 25.1602 23.1373L18.1456 16.1227L25.1602 9.10819C25.7053 8.5631 25.7053 7.64504 25.1602 7.09994Z"
        fill="black"
      />
    </svg>
  );
};
