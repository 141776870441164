import { useState } from "react";
import { FaApple, FaFacebookF } from "react-icons/fa";
import { LoginSocialFacebook } from "reactjs-social-login";
import { socialSignIn } from "../services/auth.service";
import { LoginProviders } from "../enums/login-providers.enum";

export const SocialButtons = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleFacebookLogin = async (facebookResponse: any) => {
    try {
      setLoading(true);
      await socialSignIn({
        loginProvider: LoginProviders.Facebook,
        accessToken: facebookResponse.data.accessToken,
      });
      console.log(`Logado com sucesso!`);
    } catch (error) {
      console.error("Erro ao realizar login com o Facebook:", error);
    }
  };

  return (
    <div className="w-100 d-flex align-items-center justify-content-between">
      <LoginSocialFacebook
        className="btn btn-socials btn-facebook"
        appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
        onResolve={handleFacebookLogin}
        onReject={(error) => console.error(error)}
        isDisabled={loading}
      >
        <span className="d-none d-md-inline-block align-middle">Facebook</span>
        <FaFacebookF className="float-none float-md-end" />
      </LoginSocialFacebook>

      <button className="mx-4 btn btn-socials btn-google">
        <span className="d-none d-md-inline-block align-middle">Google</span>
        <img
          src="/images/icons/google.png"
          alt="google"
          className="float-none float-md-end"
        />
      </button>

      <button className="btn btn-socials btn-apple">
        <span className="d-none d-md-inline-block align-middle">Apple</span>
        <FaApple className="float-none float-md-end text-white" />
      </button>
    </div>
  );
};
