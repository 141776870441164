import { useForm } from "react-hook-form";
import AdminLayout from "../../../components/Layout/Admin";
import Input from "../../../components/Form/Input";
import { useParams } from "react-router-dom";

interface FormValues {
  title: string;
  description: string;
}

export default function PushNotification() {
  const { postId } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      title: "",
      description: "",
    },
  });

  async function handlePushNotification(data: FormValues): Promise<void> {
    console.log("Post ID:", postId, "args:", data);
  }

  return (
    <AdminLayout>
      <div className="container-fluid">
        <h2 className="mb-3">Enviar push genérico</h2>

        <form onSubmit={handleSubmit(handlePushNotification)}>
          <div className="mb-3">
            <label htmlFor="title" className="mb-1">
              Título
            </label>
            <Input
              control={control}
              errors={errors}
              type="text"
              name="title"
              validation={{ required: "Campo obrigatório" }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="description" className="mb-1">
              Descrição
            </label>
            <Input
              control={control}
              errors={errors}
              type="text"
              name="description"
              validation={{ required: "Campo obrigatório" }}
            />
          </div>
          <button className="btn btn-success">Enviar</button>
        </form>
      </div>
    </AdminLayout>
  );
}
