import { Link } from "react-router-dom";

interface Props {
  text: string;
  href: string;
}

export const BackLink = ({ text, href }: Props) => {
  return (
    <Link
      to={href}
      className="d-flex justify-content-center align-items-center arrow-back-text"
    >
      <div className="me-2 arrow-back-icon">
        <img src="/images/icons/arrow-left-blue.png" alt="arrow-left-blue" />
      </div>
      {text}
    </Link>
  );
};
