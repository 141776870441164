import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import AdminLayout from "../../../components/Layout/Admin";
import { Loading } from "../../../components/Loading";
import SettingsArgs from "../../../interfaces/args/settings.args";
import { postSettings } from "../../../services/admin.service";
import { getSettings } from "../../../services/settings.service";

export default function Parameters() {
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit, setValue } = useForm<SettingsArgs>({
    values: {
      sendPushWhenNewItemAdded: false,
      showGroupMessage: false,
      usersCanAddNewPosts: false,
      telegramGroupLink: "",
      whatsAppGroupLink: "",
    },
  });

  async function onSubmit(data: SettingsArgs): Promise<void> {
    setLoading(true);

    postSettings(data)
      .then(() => {
        toast.success("Parâmetros atualizados com sucesso");
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao atualizar parâmetros");
      })
      .finally(() => setLoading(false));
  }

  async function getParameters(): Promise<void> {
    setLoading(true);

    getSettings()
      .then(({ data }) => {
        setValue("showGroupMessage", data.showGroupMessage);
        setValue("usersCanAddNewPosts", data.permitirUsuariosCadastrarItens);
        setValue("telegramGroupLink", data.telegramGroupLink);
        setValue("whatsAppGroupLink", data.whatsappGroupLink);
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao carregar parâmetros");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getParameters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AdminLayout>
      <div className="container-fluid">
        <h2 className="mb-3">Parâmetros</h2>

        {loading && <Loading />}

        {!loading && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12 d-flex align-items-center mb-3">
              <label htmlFor="EnviarPushAoCadastrarItem" className="me-2">
                Enviar push ao cadastrar itens?
              </label>
              <input
                {...register("sendPushWhenNewItemAdded")}
                type="checkbox"
                id="EnviarPushAoCadastrarItem"
              />
            </div>
            <div className="col-md-12 d-flex align-items-center mb-3">
              <label htmlFor="PermitirUsuariosCadastrarItens" className="me-2">
                Permitir usuários cadastrar itens?
              </label>
              <input
                {...register("usersCanAddNewPosts")}
                type="checkbox"
                id="PermitirUsuariosCadastrarItens"
              />
            </div>
            <div className="col-md-12 d-flex align-items-center mb-3">
              <label htmlFor="ShowGroupMessage" className="me-2">
                Exibir popup do grupo de ofertas?
              </label>
              <input
                {...register("showGroupMessage")}
                type="checkbox"
                id="ShowGroupMessage"
              />
            </div>
            <div className="col-md-12 d-flex align-items-center mb-3">
              <label htmlFor="WhatsappGroupLink" className="me-2">
                Link do grupo do WhatsApp:
              </label>
              <input
                {...register("whatsAppGroupLink")}
                type="text"
                id="WhatsappGroupLink"
                className="form-control"
                style={{ width: "300px" }}
              />
            </div>
            <div className="col-md-12 d-flex align-items-center mb-3">
              <label htmlFor="TelegramGroupLink" className="me-2">
                Link do grupo do Telegram:
              </label>
              <input
                {...register("telegramGroupLink")}
                type="text"
                id="TelegramGroupLink"
                className="form-control"
                style={{ width: "300px" }}
              />
            </div>

            <button type="submit" className="btn btn-success w-25 mt-5">
              Atualizar
            </button>
          </form>
        )}
      </div>
    </AdminLayout>
  );
}
