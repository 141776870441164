import AuthModel from "../interfaces/models/auth.model";

export const useCheckAuth = (): boolean => {
  let auth: AuthModel = {} as AuthModel;

  const authLocalStr: string | null = localStorage.getItem("auth");
  //const authSessionStr: string | null = sessionStorage.getItem("auth");

  if (authLocalStr) {
    auth = JSON.parse(authLocalStr);
  /*} else if (authSessionStr) {
    auth = JSON.parse(authSessionStr);*/
  } else {
    return false;
  }

  let dateNow = new Date();
  let tokenExpiresDate = new Date(auth.expires);

  if (dateNow > tokenExpiresDate && !auth.refreshToken) {
    return false;
  }

  return true;
};
