import PostModel from "../interfaces/models/post.model";
import api from "./client/api-client";
import CategoryModel from "../interfaces/models/category.model";
import PostArgs from "../interfaces/args/post.args";

export const postPosts = async (args: PostArgs) =>
  await api.post("/posts", args);

export const uploadImage = async (imageData: FormData) =>
  await api.post<{ url: string }>("/uploads/posts", imageData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getPostsFeatured = async (query: string | null) => {
  let url: string = `/posts/getfeatured`;

  /*
    const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${currentDate
    .getHours()
    .toString()
    .padStart(2, "0")}-${currentDate.getMinutes().toString().padStart(2, "0")}`;

  url += `?initialDate=${formattedDate}`;
  */

  url += `?initialDate=2024-02-11-19-47`;
  if (query) {
    url += `&filter=${query}`;
  }

  return await api.get<PostModel[]>(url);
};

export const getPostsNext = async (query: string | null) => {
  let url: string = `/posts/getnext`;
  url += `?initialDate=2024-02-11-19-47`;
  if (query) {
    url += `&filter=${query}`;
  }

  return await api.get<PostModel[]>(url);
};

export const getPostsByCategory = async (
  query: string | null,
  categoryId?: string
) => {
  let url: string = `/posts/getbycategory`;
  url += `?initialDate=2024-02-11-19-47`;
  if (categoryId) {
    url += `&categoryId=${categoryId}`;
  }

  if (query) {
    url += `&filter=${query}`;
  }

  return await api.get<PostModel[]>(url);
};

export const getPostById = async (postId: string) =>
  await api.get<PostModel>(`/posts/${postId}`);

export const getCategories = async () => {
  return await api.get<CategoryModel[]>("/categories");
};
