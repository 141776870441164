import MainLayout from "../../components/Layout/Main";
import { Form } from "./components/form";

export default function Profile() {
  return (
    <MainLayout background="white">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h3 className="text-center m-0 text-gilroy-semibold">Configurações de perfil</h3>

        <Form />
      </div>
    </MainLayout>
  );
}
