import { useState } from "react";
import CleanLayout from "../../components/Layout/Clean";
import { useForm } from "react-hook-form";
import RecoverArgs from "../../interfaces/args/recover.args";
import { forgotPassword } from "../../services/auth.service";
import toast from "react-hot-toast";
import Input from "../../components/Form/Input";
import { Loading } from "../../components/Loading";
import { FaEnvelope } from "react-icons/fa";
import { BackLink } from "../Register/components/back-link";

interface FormValues {
  email: string;
}

export default function RecoverPassword() {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      email: "",
    },
  });

  async function handleForgotPassword(data: FormValues): Promise<void> {
    let args: RecoverArgs = {
      email: data.email,
    };

    setLoading(true);

    forgotPassword(args)
      .then(() => {
        toast.success(
          `Enviamos um e-mail para ${data.email} para a redefinição da senha.`
        );
      })
      .catch((err) => {
        toast.error(err.response.data[0] ?? "Erro ao enviar e-mail de recuperação");
      })
      .finally(() => setLoading(false));
  }

  return (
    <CleanLayout title="Informe seu e-mail para recuperar a senha!">
      <form
        onSubmit={handleSubmit(handleForgotPassword)}
        className="w-100 mb-4"
      >
        <div className="row">
          <div className="col-md-12 w-100">
            <Input
              control={control}
              errors={errors}
              name="email"
              validation={{
                required: "Campo e-mail é obrigatório",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Endereço de e-mail é inválido",
                },
              }}
              type="email"
              placeholder="exemplo@email.com"
            />
          </div>
        </div>

        <button
          type={loading ? "button" : "submit"}
          className="btn btn-green w-100 mt-3 border-0"
          disabled={loading}
        >
          {loading ? (
            <Loading />
          ) : (
            <span className="align-middle">Enviar e-mail</span>
          )}

          <FaEnvelope />
        </button>
      </form>

      <BackLink text="Voltar para login" href="/login" />
    </CleanLayout>
  );
}
