import ProfilePostArgs from "../interfaces/args/profile-post.args";
import UserModel from "../interfaces/models/user.model";
import api from "./client/api-client";

export const getUserProfile = async () => await api.get<UserModel>("/profile");

export const postUserProfile = async (args: ProfilePostArgs) =>
  api.post<UserModel>("/profile", args);

export const uploadUserImage = async (imageData: FormData) =>
  await api.post<{ url: string }>("/uploads/users", imageData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteUser = async () => await api.delete<UserModel>("/profile");
