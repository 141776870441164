import { FaWhatsapp } from "react-icons/fa";
import MainLayout from "../../../components/Layout/Main";
import { Filtro } from "../components/filtro";
import { IoLocationSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import PostModel from "../../../interfaces/models/post.model";
import { getPostsNext } from "../../../services/posts.service";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";
import { Link, useSearchParams } from "react-router-dom";

export default function AllPosts() {
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.get("query");

  const [posts, setPosts] = useState<PostModel[]>([]);

  const localStorageKey = "allPosts";

  const saveToLocalStorage = (data: PostModel[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  const loadFromLocalStorage = (): PostModel[] => {
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : [];
  };

  async function fetchPosts(): Promise<void> {
    setLoading(true);

    getPostsNext(queryString)
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorage();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorage(fetchedPosts);
          setPosts(fetchedPosts);
        } else {
          setPosts(storedPosts);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const storedPosts = loadFromLocalStorage();
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
    }

    fetchPosts();
  }, [queryString]);

  return (
    <MainLayout>
      <div className="container mw-100">
        <div className="row">
          <div className="col-md-3">
            <Filtro />
          </div>

          <div className="col-md-9">
            <div className="align-items-center flex-column flex-md-row mb-5 text-center text-md-start">
              <h4 className="color-primary fw-semibold text-gilroy-semibold">
                Ofertas Recentes
              </h4>
              <p className="color-secondary fw-semibold small-text mb-0 text-gilroy-semibold">
                Ofertas recém adicionadas
              </p>
            </div>

            <div className="row mb-4">
              {loading && <Loading center />}

              {!loading &&
                posts.map((post, index) => (
                  <div
                    key={index}
                    className="col-12 col-sm-6 col-md-4 col-lg-3 col-sm-6 mt-30 position-relative"
                  >
                    <div>
                      <div className="badge position-absolute text-center bg-white d-flex align-items-center">
                        <span className="badge-category fw-bold mx-2 text-gilroy-bold">
                          R$ {post.preco}
                        </span>
                        <span className="badge-category-description fw-bold text-gilroy-semibold">
                          {post.pagamento}
                        </span>
                      </div>
                      {post.poster && (
                        <Link to={`/posts/${post.id}`}>
                          <img
                            src={post.poster}
                            className="img-poster"
                            alt="Imagem"
                          />
                        </Link>
                      )}
                      <img
                        src={`/images/categories/${post.category?.systemKey}_up.svg`}
                        className="img-fluid mt-n1"
                        style={{ marginTop: "-20px" }}
                        alt={post.category?.title}
                      />
                      <div
                        className="d-flex icons-category justify-content-end mb-2"
                        style={{ marginRight: "-25px" }}
                      >
                        <div className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2">
                          <FaWhatsapp className="text-white" />
                        </div>
                        <IconShare post={post} />
                      </div>
                      <div className="text-card mt-2">
                        <p
                          className="fw-semibold text-md-start text-gilroy-semibold"
                          style={{ marginBottom: "-2px" }}
                        >
                          {post.title}
                        </p>
                        <div className="d-flex align-items-center mb-5">
                          <IoLocationSharp className="icon-color" />
                          <p
                            className="mb-0 mx-1 text-dark-emphasis fw-semibold text-gilroy-semibold"
                            style={{ fontSize: "13px" }}
                          >
                            {post.cidade}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
