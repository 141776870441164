import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/Layout/Admin";
import UserModel from "../../interfaces/models/user.model";
import { Loading } from "../../components/Loading";
import { getUsersAdmin, putStatusUser } from "../../services/admin.service";
import toast from "react-hot-toast";
import { HiChevronDown } from "react-icons/hi";

export default function Users() {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [filterBy, setFilterBy] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [filterCriterion, setFilterCriterion] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(100);

  async function fetchUsers(): Promise<void> {
    setLoading(true);

    getUsersAdmin()
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  async function handleStatusUser(userId: string): Promise<void> {
    setLoading(true);

    putStatusUser(userId)
      .then(() => {
        toast.success("Estado do usuário alterado com sucesso!");
        fetchUsers();
      })
      .catch((err) => {
        toast.error(
          err?.response?.data[0] ??
            "Ocorreu um erro inesperado ao atualizar o estado do usuário."
        );
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleFilterAndSort = (
    filterBy: string,
    sortBy: string,
    filterCriterion: string
  ): UserModel[] => {
    let filteredUsers = [...users];

    // Filtragem
    if (filterBy === "name") {
      filteredUsers.sort((a, b) =>
        (a.displayName ?? "").toLowerCase() <
        (b.displayName ?? "").toLowerCase()
          ? -1
          : 1
      );
    } else if (filterBy === "date") {
      filteredUsers.sort((a, b) =>
        new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1
      );
    } else if (filterBy === "email") {
      filteredUsers.sort((a, b) =>
        (a.email ?? "").toLowerCase() < (b.email ?? "").toLowerCase() ? -1 : 1
      );
    }

    // Ordenação
    if (sortBy === "asc") {
      filteredUsers.reverse();
    }

    return filteredUsers.filter((user) => {
      if (filterCriterion) {
        if (filterBy === "name" && user.displayName) {
          return user.displayName
            .toLowerCase()
            .includes(filterCriterion.toLowerCase());
        } else if (filterBy === "date" && user.createdAt) {
          return user.createdAt
            .toLowerCase()
            .includes(filterCriterion.toLowerCase());
        } else if (filterBy === "email" && user.email) {
          return user.email
            .toLowerCase()
            .includes(filterCriterion.toLowerCase());
        }
      }
      return true;
    });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = handleFilterAndSort(filterBy, sortBy, filterCriterion)
    .filter(
      (user) =>
        user.displayName &&
        user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  return (
    <AdminLayout>
      <main className="container-fluid">
        <h1 className="mb-4">Usuários</h1>
        <div className="d-flex align-itens-center mb-3">
          <div>
            <input
              type="text"
              placeholder="Pesquisar"
              value={searchTerm}
              onChange={handleSearch}
              className="form-control"
            />
          </div>
        </div>

        {loading && (
          <div className="d-flex align-items-center justify-content-center">
            <Loading center color="black" />
          </div>
        )}

        {/* Table */}
        {!loading && users.length > 0 && (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th
                    className="cursor-pointer"
                    scope="col"
                    onClick={() => setFilterBy("name")}
                  >
                    <span>Display Name</span>
                    <span className="mx-1">
                      <HiChevronDown />
                    </span>
                  </th>
                  <th
                    className="cursor-pointer"
                    scope="col"
                    onClick={() => setFilterBy("email")}
                  >
                    <span>Email</span>
                    <span className="mx-1">
                      <HiChevronDown />
                    </span>
                  </th>
                  <th
                    className="cursor-pointer"
                    scope="col"
                    onClick={() => setFilterBy("date")}
                  >
                    <span>Register Date</span>
                    <span className="mx-1">
                      <HiChevronDown />
                    </span>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((user) => (
                  <tr key={user.id}>
                    <td>
                      {user.profilePicture ? (
                        <img
                          src={user.profilePicture}
                          alt="Profile"
                          height="59px"
                          width="59"
                        />
                      ) : (
                        "Sem Foto"
                      )}
                    </td>
                    <td>{user.displayName}</td>
                    <td>{user.email}</td>
                    <td>{user.createdAt}</td>
                    <td>
                      <button
                        className={`btn ${
                          user.blocked ? "btn-success" : "btn-danger"
                        }`}
                        onClick={() => handleStatusUser(user.id)}
                      >
                        {user.blocked ? "Unblock" : "Block"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <nav aria-label="Pagination">
          <ul className="pagination">
            <li className="page-item">
              <button
                className={`page-link cursor-pointer ${
                  currentPage === 1 ? "disabled-button" : ""
                }`}
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="text-black fw-bold">Anterior</span>
              </button>
            </li>
            <li className="page-item">
              <button
                className={"page-link cursor-pointer"}
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  indexOfLastItem >=
                  handleFilterAndSort(filterBy, sortBy, filterCriterion).length
                }
              >
                <span className="text-black fw-bold">Próximo</span>
              </button>
            </li>
          </ul>
        </nav>
      </main>
    </AdminLayout>
  );
}
