import { CookieConsent } from "react-cookie-consent";
import { useCookies } from "react-cookie";

export const CookieConsentAccept = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };

  return (
    <>
      {!cookies.cookieConsent && (
        <CookieConsent
          debug={true}
          style={{
            background: "#eee",
            color: "#000",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.2)",
            paddingLeft: "15px",
            paddingRight: "15px",
            fontFamily: "gilroy-semibold, sans-serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          buttonStyle={{
            background: "#c31fff",
            color: "#fff",
            borderRadius: "20px",
            fontFamily: "gilroy-semibold, sans-serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "150px",
            height: "40px",
          }}
          buttonText="Aceitar cookies"
          onAccept={giveCookieConsent}
          enableDeclineButton
          declineButtonText="Não aceitar"
          declineButtonStyle={{
            background: "#BEBEBE",
            color: "#000",
            borderRadius: "20px",
            fontFamily: "gilroy-semibold, sans-serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "150px",
            height: "40px",
          }}
        >
          Nós usamos cookies e outras tecnologias semelhantes para melhorar a
          sua experiência em nossos serviços, personalizar publicidade e
          recomendar conteúdo de seu interesse. Ao utilizar nossos serviços,
          você está ciente dessa funcionalidade. Informamos ainda que
          atualizamos nosso Aviso de Privacidade. Conheça nosso{" "}
          <a href="/privacy">Portal da Privacidade</a> e veja o nosso novo
          aviso.
        </CookieConsent>
      )}
    </>
  );
};
