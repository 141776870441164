import AdminLayout from "../../../../components/Layout/Admin";
import { Form } from "./components/form";

export default function AdminPostEdit() {
  return (
    <AdminLayout>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h3 className="text-center m-0">Editar promoção</h3>

        <Form />
      </div>
    </AdminLayout>
  );
}
