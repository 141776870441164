import { Link } from "react-router-dom";
import CleanLayout from "../../components/Layout/Clean";
import { SocialButtons } from "../../components/social-buttons";
import { Form } from "./components/form";

export default function Login() {
  return (
    <CleanLayout title="Faça seu login aqui para começar!">
      <SocialButtons />

      <div className="row g-0 w-100 mt-4 mb-2">
        <h2 className="or-with-email">
          <span className="col-3 font-weight-semibold font-size-16 text-primary-opacity-50 text-gilroy-semibold">
            ou entre com email
          </span>
        </h2>
      </div>

      <Form />

      <div className="d-flex flex-column justify-content-center align-items-center">
        <p
          className="m-0 text-center text-gray"
          style={{
            fontSize: 16,
          }}
        >
          Ainda não possui cadastro?{" "}
          <Link to="/register" className="link-green">
            Cadastre-se aqui.
          </Link>
        </p>

        <p
          className="m-0 text-gray"
          style={{
            fontSize: 16,
          }}
        >
          Esqueceu sua senha?{" "}
          <Link to="/account/recover" className="link-green">
            Recupere aqui.
          </Link>
        </p>
      </div>
    </CleanLayout>
  );
}
